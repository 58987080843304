import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ExpertInfo: JSON.parse(localStorage.getItem("ExpertInfo")),
    login: localStorage.getItem('TOKEN'),
    record: {
      id: [],
      dg: [],
      sp: [],
      college: [],
      specialty: [],
    },
    userData: {
      score: 0
    },
    oldLineScore: {
    },
    equalScore: {
    }
  },
  mutations: {
    setInfo(state, info) {
      state.ExpertInfo = info
    },
    loginOut(state) {
      state.ExpertInfo = ''
    },
    setRecord(state, data) {
      state.record = data
    },
    setUserData(state, data) {
      state.userData = data
    },
    setOldLineScore(state, data) {
      state.oldLineScore = data
    },
    setEqualScore(state, data) {
      state.equalScore = data
    }
  },
  actions: {
  },
  modules: {
  }
})
