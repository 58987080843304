import Axios from 'axios';
import Router from './router';
import { Notification } from 'element-ui';

//请求拦截器
Axios.interceptors.request.use(config => {
    //判断token
    const TOKEN = localStorage.getItem('TOKEN');
    if (TOKEN) {
        config.headers['Token'] = TOKEN;
    }
    // config.baseURL = 'http://www.newluming.com/index.php/expert/'; //本地地址
    config.baseURL = 'https://daoshi.bkgaoshou.com/index.php/expert/'; //本地地址
    return config;
}, function (error) { //请求失败
    Notification({
        offset: 200,
        message: '网络链接异常！',
        type: 'error'
    });
    return Promise.reject(error);
});

//响应拦截器
Axios.interceptors.response.use(function (response) {
    let res = response.data;
    //响应之后
    if (res.code === "300") {
        if (res.msg) {
            Notification({
                offset: 200,
                message: res.msg,
                type: 'error'
            });
        }
        localStorage.clear();
        Router.push("/login")
    } else {
        return res;
    }
}, function (error) { //响应失败
    Notification({
        offset: 200,
        message: '网络链接异常！',
        type: 'error'
    });
    return Promise.reject(error);
});
export default Axios;


