import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login'),
    },

    {
        path: '/PdfRecommend',
        name: 'PdfRecommend',
        component: () => import('../views/filling/volunteer/PdfRecommend'),
    },
    {
        path: '/PdfVolunteer',
        name: 'PdfVolunteer',
        component: () => import('../views/filling/volunteer/PdfVolunteer'),
    },
    {
        path: '/',
        name: '',
        component: () => import('../views/index'),
        children: [
            {
                path: '/',
                name: 'info',
                component: () => import('../views/expert/Info'),
            },
            {
                path: '/about_us',
                name: 'about_us',
                component: () => import('../views/SystemConfig/aboutUs'),
            },
            {
                path: '/reviewed_info',
                name: 'reviewed_info',
                component: () => import('../views/reviewed/user_info/index'),
            },
            {
                path: '/user_info',
                component: () => import('../views/user/userInfo/'),
                children: [
                    {
                        path: '/',
                        name: 'user_info',
                        component: () => import('../views/user/userInfo/UserInfo'),
                    },
                ]
            },
            {
                // reviewed 审核
                path: '/reviewed',
                component: () => import('../views/reviewed/'),
                children: [
                    {
                        path: '/',
                        name: 'reviewed',
                        component: () => import('../views/reviewed'),
                    },
                ]
            },

            {
                path: '/pdf_plan',
                component: () => import('../views/pdf_plan'),
                children: [
                    {
                        path: 'plan',
                        name: 'plan',
                        component: () => import('../views/pdf_plan/plan'),
                    },
                    {
                        path: 'zhengce',
                        name: 'zhengce',
                        component: () => import('../views/pdf_plan/zhengce'),
                    },
                ]
            },

            {
                path: '/expert/expert',
                name: 'expert',
                component: () => import('../views/expert/Expert'),
            },
            {
                path: '/editExpert',
                name: 'editExpert',
                component: () => import('../views/expert/EditExpert'),
            },
            {
                path: '/report',
                name: 'report',
                component: () => import('../views/user/report'),
            },
            {
                path: '/expert_list',
                name: 'expert_list',
                component: () => import('../views/expert/ExpertList'),
            },
            {
                path: '/user',
                name: 'user',
                component: () => import('../views/user/User'),
            },
            {
                path: '/vipUser',
                name: 'vipUser',
                component: () => import('../views/user/vipUser'),
            },
            {
                path: '/news',
                name: 'news',
                component: () => import('../views/News'),
            },

            {
                path: '/create_card',
                name: 'create_card',
                component: () => import('../views/card/CreateCard'),
            },
            {
                path: '/goods',
                name: 'goods',
                component: () => import('../views/SystemConfig/goods'),
            },
            {
                path: '/order',
                name: 'order',
                component: () => import('../views/SystemConfig/order'),
            },
            {
                path: '/card_list',
                name: 'card_list',
                component: () => import('../views/card/CardList'),
            },
            {
                path: '/rule',
                name: 'rule',
                component: () => import('../views/card/rule'),
            },
            {
                path: '/banner',
                name: 'banner',
                component: () => import('../views/SystemConfig/banner'),
            },
            {
                path: '/system_news',
                name: 'system_news',
                component: () => import('../views/systemNews'),
            },
            {
                path: '/service',
                name: 'service',
                component: () => import('../views/SystemConfig/service'),
            },
            {
                path: '/system_message',
                name: 'system_message',
                component: () => import('../views/message/SystemMessage'),
            },
            {
                path: '/user_message',
                name: 'user_message',
                component: () => import('../views/message/UserMessage'),
            },
            {
                path: '/SearchSchool',
                name: 'SearchSchool',
                component: () => import('../views/zhuanjiamoshi/SearchSchool'),
            },


            {
                path: '/filling',
                name: 'filling',
                component: () => import('../views/filling/index'),

            },

            {
                path: 'volunteer',
                name: 'volunteer',
                component: () => import("../views/filling/volunteer/index"),
            },
            {
                path: '/SearchSpecialty',
                name: 'SearchSpecialty',
                component: () => import('../views/specialty/SearchSpecialty'),
            },
            // {
            //     path: '/Cuser',
            //     name: 'CreateUser',
            //     component: () => import('../views/user/CreateUser'),
            // },
            {
                path: '/toAssess',
                name: 'toAssess',
                component: () => import('../views/toAssess'),
            },
            {
                path: '/addUser',
                name: 'addUser',
                component: () => import('../views/user/addUser'),
            }
        ]
    },
    {
        path: "/eqResult",
        name: "eqResult",
        component: () =>
            import("../views/assess/eqResult")
    },
    {
        path: "/sclResult",
        name: "sclResult",
        component: () =>
            import("../views/assess/sclResult")
    },
    {
        path: "/interest_w_result",
        name: "interest_w_result",
        component: () =>
            import("../views/assess/interest_w_result")
    },
    {
        path: "/interest_l_result",
        name: "interest_l_result",
        component: () =>
            import("../views/assess/interest_l_result")
    },
    {
        path: '/solution',
        name: 'solution',
        component: () => import('../views/zhuanjiamoshi/solution'),
    },
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    // mode: "history",
    base: process.env.BASE_URL,
    routes
});


router.beforeEach((to, from, next) => {
    // console.log(to.name)
    // to: Route: 即将要进入的目标 路由对象
    // from: Route: 当前导航正要离开的路由
    // next: Function: 一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。
    const ExpertInfo = localStorage.getItem('ExpertInfo') // 是否登录
    // 未登录状态；当路由到nextRoute指定页时，跳转至login

    if (!ExpertInfo && to.name !== 'login') {
        router.push('/Login')
    } else {
        next()
    }
    // // 已登录状态；当路由到login时，跳转至home
    if (to.name === 'login') {
        if (ExpertInfo) {
            router.push('/')
        } else {
            next()
        }
    }
    next();
})

export default router
