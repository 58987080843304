
const interpo = function (value) {
    if (value == null || value == '') {
        return ''
    }
    let str = ''
    if (value.length == 1) {
        str = '0' + value
    } else {
        str = value
    }
    return str
}

let changeZero = function (num) {
    if (num == 0 || num == null) {
        num = '-'
    }
    return num
}
export { interpo, changeZero }