import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'default-passive-events'
import 'element-ui/lib/theme-chalk/index.css';
import Axios from './Axios'
import * as Filters from './utils/filters.js'
import htmlToPdf from './utils/htmlToPdf'
Object.keys(Filters).forEach(key => {
  Vue.filter(key, Filters[key])
});
Vue.use(ElementUI);
Vue.use(htmlToPdf)
Vue.prototype.$fecth = Axios;
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
